import React from 'react';
import { graphql, PageProps, navigate, Link } from 'gatsby';
import Layout from '../components/Layout';
import { ListItem } from '../components/Gallery';
import NotFoundPage from '../pages/404';
import CloseIcon from '../images/ui/icon-close.inline.svg';
import { WorldsFairHead } from '../components/Head';

const Artwork = ({ data: { artwork, gallery } }: PageProps<Queries.ArtworkTemplateQuery>) => {
    if (!artwork || !gallery) {
        return <NotFoundPage />;
    }

    const {
        slug: gallerySlug
    } = gallery;

    return (
        <Layout location="artwork" chrome={false} showSignup={false} seoData={artwork.seo}>
            <Link to={`/${gallerySlug}`} className="artwork-back-link" aria-label="Back to Gallery">
                <CloseIcon />
            </Link>
            {gallerySlug &&
                <ListItem lazy={false} large={true} gallerySlug={gallerySlug} imgStyle={{ objectFit: 'contain' }} {...artwork} />
            }
        </Layout>
    );
}

export default Artwork;

export const query = graphql`
    query ArtworkTemplate ($id: Int!, $galleryTemplateName: String!) {
        artwork: wpArtwork(databaseId: { eq: $id }) {
            ...ArtworkData
            ...SeoData
        }

        gallery: wpPage(template: { templateName: { eq: $galleryTemplateName }}) {
            slug
        }
    }
`

export const Head = () => <WorldsFairHead />
